<template>
  <figure class="icon org-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 21H22"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <rect
        x="4"
        y="13"
        width="4"
        height="8"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linejoin="round"
      ></rect>
      <path
        d="M5 16.7458H7"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      ></path>
      <rect
        x="8"
        y="2"
        width="12"
        height="19"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linejoin="round"
      ></rect>
      <rect x="11" y="5" width="2" height="2" fill="#373F41"></rect>
      <rect x="15" y="5" width="2" height="2" fill="#373F41"></rect>
      <rect x="11" y="8.5" width="2" height="2" fill="#373F41"></rect>
      <rect x="15" y="8.5" width="2" height="2" fill="#373F41"></rect>
      <rect x="15" y="12" width="2" height="2" fill="#373F41"></rect>
      <rect x="15" y="15.5" width="2" height="2" fill="#373F41"></rect>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "OrgIcon",
};
</script>
