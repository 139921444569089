<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form class="auth__steps-form">
    <InputComponent
      title="Электронная почта"
      type="email"
      :error="$v.form.email.value.$error"
      v-model.trim.lazy="$v.form.email.value.$model"
    />
    <InputComponent
      title="Введите пароль"
      :type="!showPassword ? 'password' : 'text'"
      :error="$v.form.password.value.$error"
      v-model.trim.lazy="$v.form.password.value.$model"
      @submit="showPassword = !showPassword"
    >
      <template v-slot:action="">
        <EyeOffIcon v-if="!showPassword" />
        <EyeOnIcon v-else />
      </template>
    </InputComponent>
    <InputComponent
      title="Повторите пароль"
      :type="!showConfirmPassword ? 'password' : 'text'"
      :error="$v.form.password_confirm.value.$error"
      v-model.trim.lazy="$v.form.password_confirm.value.$model"
      @submit="showConfirmPassword = !showConfirmPassword"
    >
      <template v-slot:action="">
        <EyeOffIcon v-if="!showConfirmPassword" />
        <EyeOnIcon v-else />
      </template>
    </InputComponent>
  </form>
</template>

<script>
import InputComponent from "@/components/inputs/InputComponent";
import EyeOffIcon from "@/components/svg/EyeOffIcon";
import EyeOnIcon from "@/components/svg/EyeOnIcon";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "RegLoginStep",
  components: { EyeOnIcon, EyeOffIcon, InputComponent },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      form: {
        email: {
          message: null,
          value: null,
        },
        password: {
          message: null,
          value: null,
        },
        password_confirm: {
          message: null,
          value: null,
        },
      },
    };
  },
  validations: {
    form: {
      email: {
        value: {
          required,
          email,
          minLength: minLength(5),
        },
      },
      password: {
        value: {
          required,
          minLength: minLength(8),
        },
      },
      password_confirm: {
        value: {
          required,
          sameAsPassword: sameAs(function () {
            return this.form.password.value;
          }),
        },
      },
    },
  },
  methods: {
    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    getData() {
      let data = {};
      if (this.isValid()) {
        Object.keys(this.form).forEach((fK) => {
          if (this.form[fK].value) {
            if (typeof this.form[fK].value === "object") {
              if (this.form[fK].value instanceof Array) {
                data[fK] = this.form[fK].value.map((v) => v.id);
              } else {
                data[fK] = this.form[fK].value.id;
              }
            } else {
              data[fK] = this.form[fK].value;
            }
          }
        });
      }
      return data;
    },
  },
};
</script>
