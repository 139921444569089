<template>
  <figure class="icon list-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4"
        y="2"
        width="16"
        height="20"
        rx="2"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linejoin="round"
      ></rect>
      <path
        d="M10.5 7H16.5"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M10.5 12H16.5"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M10.5 17H16.5"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 8C8.05228 8 8.5 7.55228 8.5 7C8.5 6.44772 8.05228 6 7.5 6C6.94772 6 6.5 6.44772 6.5 7C6.5 7.55228 6.94772 8 7.5 8Z"
        fill="#373F41"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 13C8.05228 13 8.5 12.5523 8.5 12C8.5 11.4477 8.05228 11 7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13Z"
        fill="#373F41"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 18C8.05228 18 8.5 17.5523 8.5 17C8.5 16.4477 8.05228 16 7.5 16C6.94772 16 6.5 16.4477 6.5 17C6.5 17.5523 6.94772 18 7.5 18Z"
        fill="#373F41"
      ></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "ListIcon",
};
</script>
