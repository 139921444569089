<template>
  <section class="auth auth--reg">
    <div class="auth__container">
      <div class="auth__logo">
        <FullLogoIcon />
      </div>
      <div class="auth__form">
        <h1 class="auth__title">Регистрация</h1>
        <ul class="steps">
          <li
            class="step"
            :class="{
              'step--active': currentStep === i,
              'step--done': currentStep > i,
            }"
            v-for="(step, i) in steps"
            :key="i"
          >
            <div class="step-icon">
              <CheckSymbolIcon />
              <Component :is="step.icon" />
            </div>
            <span class="step-name">{{ step.title }}</span>
          </li>
        </ul>
        <template v-for="(step, i) in steps">
          <Component :is="step.step" v-show="currentStep === i" ref="step" :key="i" />
        </template>
        <div class="auth__controls" :class="{ 'auth__controls--full': currentStep === 0 }">
          <button type="button" class="back-btn" @click="changeStep(-1)" v-if="currentStep !== 0">
            <ChevronLeftIcon />
            <span>Назад</span>
          </button>
          <button
            type="button"
            class="btn"
            :class="{
              'btn--icon': !isLastStep,
              'btn--loading': loading,
            }"
            @click="changeStep(1)"
          >
            <template v-if="!loading">
              <span>{{ isLastStep ? "Зарегистрироваться" : "Далее" }} </span>
              <ChevronRightIcon v-if="!isLastStep" />
            </template>
          </button>
        </div>
      </div>
      <div class="auth__switch">
        <span>Уже есть аккаунт?</span>
        <router-link :to="{ name: 'Login' }">Войти</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import FullLogoIcon from "@/components/svg/FullLogoIcon";
import OrgIcon from "@/components/svg/OrgIcon";
import ListIcon from "@/components/svg/ListIcon";
import UserIcon from "@/components/svg/UserIcon";
import CheckSymbolIcon from "@/components/svg/CheckSymbolIcon";
import ChevronLeftIcon from "@/components/svg/ChevronLeftIcon";
import ChevronRightIcon from "@/components/svg/ChevronRightIcon";
import RegOrgStep from "./regSteps/OrgStep";
import RoleStep from "./regSteps/RoleStep";
import LoginStep from "./regSteps/LoginStep";

export default {
  name: "RegistrationPage",
  data() {
    return {
      loading: false,
      currentStep: 0,
      steps: [
        {
          title: "Организация",
          icon: OrgIcon,
          step: RegOrgStep,
          valid: false,
        },
        {
          title: "Роль и куратор",
          icon: ListIcon,
          step: RoleStep,
          valid: false,
        },
        {
          title: "Данные для входа",
          icon: UserIcon,
          step: LoginStep,
          valid: false,
        },
      ],
    };
  },
  computed: {
    isLastStep() {
      return this.currentStep === this.steps.length - 1;
    },
  },
  beforeCreate() {
    this.$store.dispatch("GET_REGISTRATION_PAGE", {
      context: this,
    });
  },
  methods: {
    async registration() {
      if (!this.loading) {
        this.loading = true;
        let fields = [];
        this.steps.forEach((_, i) => fields.push(this.$refs.step[i].getData()));
        this.$store
          .dispatch("ADD_USER", {
            context: this,
            variables: Object.assign(...fields),
          })
          .then(({ data }) => {
            if (data && data.CreateUser) {
              this.$notify({
                title: data.CreateUser.code === 200 ? "Регистрация успешна" : "Ошибка",
                text: data.CreateUser.message,
                type: data.CreateUser.code === 200 ? "success" : "error",
                duration: 10000,
              });
              this.$router.push({ name: "Login" });
              this.loading = false;
            }
          })
          .catch(({ graphQLErrors }) => {
            this.$store.dispatch("notifyGQErrors", {
              context: this,
              errors: graphQLErrors,
            });
            this.loading = false;
          });
      }
    },
    changeStep(direction) {
      if (this.$refs.step && this.$refs.step.length) {
        if (direction > 0 && !this.$refs.step[this.currentStep].isValid()) {
          return;
        }
        if (this.currentStep + direction >= 0 && this.currentStep + direction < this.steps.length) {
          this.currentStep += direction;
        } else {
          this.registration();
        }
      }
    },
  },
  components: {
    ChevronRightIcon,
    ChevronLeftIcon,
    CheckSymbolIcon,
    FullLogoIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/auth"
@import "~@/styles/parts/steps"
</style>
