<template>
  <form class="auth__steps-form">
    <InputComponent
      title="Введите название, ИНН или ОРГН"
      type="text"
      :error="$v.form.company_name.value.$error"
      v-model.trim.lazy="$v.form.company_name.value.$model"
    />
    <InputComponent
      title="Введите ИНН"
      type="text"
      :mask="
        $v.form.inn.value.$model && $v.form.inn.value.$model.length <= 12 ? '####-#####-#' : '####-######-##'
      "
      :error="$v.form.inn.value.$error"
      v-model.lazy="$v.form.inn.value.$model"
    />
    <InputComponent
      title="Введите КПП"
      type="text"
      mask="#########"
      :error="$v.form.kpp.value.$error"
      v-model.lazy="$v.form.kpp.value.$model"
    />
    <InputComponent
      title="ФИО"
      type="text"
      :error="$v.form.fio.value.$error"
      v-model.trim.lazy="$v.form.fio.value.$model"
    />
    <InputComponent
      title="Телефон"
      type="text"
      :error="$v.form.phone.value.$error"
      v-model.trim.lazy="$v.form.phone.value.$model"
      mask="# (###) ###-##-##"
    />
    <CheckComponent v-model="$v.form.agree.value.$model" :error="$v.form.agree.value.$error">
      Даю согласие на обработку персональных данных
    </CheckComponent>
  </form>
</template>

<script>
import InputComponent from "@/components/inputs/InputComponent";
import CheckComponent from "@/components/inputs/CheckComponent";
import { minLength, maxLength, required } from "vuelidate/lib/validators";

export default {
  name: "RegOrgStep",
  data() {
    return {
      form: {
        company_name: {
          message: null,
          value: null,
        },
        inn: {
          message: null,
          value: null,
        },
        kpp: {
          message: null,
          value: null,
        },
        fio: {
          message: null,
          value: null,
        },
        phone: {
          message: null,
          value: null,
        },
        agree: {
          message: null,
          value: false,
        },
      },
    };
  },
  validations: {
    form: {
      company_name: {
        value: {
          required,
        },
      },
      inn: {
        value: {
          required,
          minLength: minLength(12),
          maxLength: maxLength(14),
        },
      },
      kpp: {
        value: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(9),
        },
      },
      fio: {
        value: {
          required,
          isFIO: (value) => {
            if (value && typeof value.split === "function") {
              return value.split(" ").length >= 3;
            }
            return false;
          },
        },
      },
      phone: {
        value: {
          required,
          minLength: minLength(17),
        },
      },
      agree: {
        value: {
          checked: (value) => value === true,
        },
      },
    },
  },
  methods: {
    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    formatData(data, key) {
      if (this.form[key].value) {
        if (key === "fio") {
          const fio = this.form[key].value.split(" ");
          if (fio.length >= 3) {
            data["surname"] = fio[0];
            data["name"] = fio[1];
            data["patronymic"] = fio.slice(2, fio.length).join(" ");
          }
        } else if (typeof this.form[key].value === "object") {
          if (this.form[key].value instanceof Array) {
            data[key] = this.form[key].value.map((v) => v.id);
          } else {
            data[key] = this.form[key].value.id;
          }
        } else {
          data[key] = this.form[key].value;
        }
      }
      return data;
    },
    getData() {
      let data = {};
      if (this.isValid()) {
        Object.keys(this.form).forEach((key) => {
          data = this.formatData(data, key);
        });
      }
      return data;
    },
  },
  components: { CheckComponent, InputComponent },
};
</script>
