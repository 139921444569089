<template>
  <form class="auth__steps-form">
    <SelectComponent
      title="Выберите роли"
      labelName="name_ru"
      v-model="$v.form.role_ids.value.$model"
      :error="$v.form.role_ids.value.$error"
      :options="roles"
      multiple
    />
    <SelectComponent
      title="Выберите ваши компетенции"
      labelName="name_ru"
      v-if="!isEmployeeRoleSelected"
      v-model="$v.form.permission_ids.value.$model"
      :error="$v.form.permission_ids.value.$error"
      :options="permissions"
      multiple
    />
    <SelectComponent
      title="Выберите куратора"
      labelName="title"
      v-model="$v.form.curator_id.value.$model"
      :error="$v.form.curator_id.value.$error"
      :options="users"
    />
  </form>
</template>

<script>
import SelectComponent from "@/components/inputs/SelectComponent";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "RegRoleStep",
  data() {
    return {
      form: {
        role_ids: {
          message: null,
          value: null,
        },
        permission_ids: {
          message: null,
          value: null,
        },
        curator_id: {
          message: null,
          value: null,
        },
      },
    };
  },
  validations: {
    form: {
      role_ids: {
        value: {
          required,
        },
      },
      permission_ids: {
        value: {
          required: requiredIf(function () {
            return !this.isEmployeeRoleSelected;
          }),
        },
      },
      curator_id: {
        value: {},
      },
    },
  },
  computed: {
    isEmployeeRoleSelected() {
      if (this.form.role_ids.value) {
        return (
          this.form.role_ids.value.filter((role) => {
            return role.parent.name === "employee";
          }).length > 0
        );
      }
      return false;
    },
    roles() {
      let roles = [];
      this.$store.state.roles.forEach((parent) => {
        parent.children.forEach((child) => {
          child.parent = {
            id: parent.id,
            name: parent.name,
            name_ru: parent.name_ru,
          };
          roles.push(child);
        });
      });
      if (this.form.role_ids.value && this.form.role_ids.value.length) {
        if (this.isEmployeeRoleSelected) {
          roles = roles.filter((role) => role.parent.name === "employee");
        } else {
          roles = roles.filter((role) => role.parent.name !== "employee");
        }
      }
      return roles;
    },
    permissions() {
      return this.$store.state.permissions;
    },
    users() {
      return this.$store.state.users
        .filter((u) => {
          return u.role.filter((r) => {
            return r.name === "supplier";
          }).length;
        })
        .map((u) => {
          u.title = `${u.surname} ${u.name} ${u.patronymic}`;
          return u;
        });
    },
  },
  watch: {
    isEmployeeRoleSelected(selected) {
      if (selected) {
        this.form.permission_ids.value = null;
      }
    },
  },
  methods: {
    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    formatData(data, key) {
      if (this.form[key].value) {
        if (key === "permission_ids") {
          data.role_ids.push(...this.form[key].value.map((p) => p.id));
        } else if (typeof this.form[key].value === "object") {
          if (this.form[key].value instanceof Array) {
            data[key] = this.form[key].value.map((v) => v.id);
          } else {
            data[key] = this.form[key].value.id;
          }
        } else {
          data[key] = this.form[key].value;
        }
      }
      return data;
    },
    getData() {
      let data = {};
      if (this.isValid()) {
        Object.keys(this.form).forEach((key) => {
          data = this.formatData(data, key);
        });
      }
      return data;
    },
  },
  components: { SelectComponent },
};
</script>
